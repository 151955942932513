@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































































































.evaluate-activity-sidebar {
  height: 100%;
}

.evaluate-activity-sidebar__comment {
  .v-input__slot {
    flex: 1 1 50vh;
  }
}
